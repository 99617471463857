<template>
  <v-container fluid>
    <report-title :title="options.title" class="mb-2" />
    <report-sub-category :tabs="options.tabs" @select-tab="selectTab" />
    <report-filter
      :organizations="options.organizations"
      @load-report="fetchReport($event)"
      @export-table="exportTableToXLSX($event)"
    />
    <report-kpi-bar />
    <report-chart :chartOptions="chartOptions" :series="series" />
    <report-table :headers="options.headers" :items="options.data" />
    <report-alert />
  </v-container>
</template>

<script>
import { Vue } from "vue-property-decorator";
import { get, sync } from "vuex-pathify";
import { mapActions, mapState } from "vuex";
import { format, startOfYear } from "date-fns";
import { json2xlsx } from "@/util/helpers";

export default Vue.extend({
  name: "ReportTerminals",
  components: {
    ReportTitle: () => import("@/components/report/ReportTitle.vue"),
    ReportChart: () => import("@/components/report/ReportChart.vue"),
    ReportSubCategory: () =>
      import("@/components/report/ReportSubCategory.vue"),
    ReportFilter: () => import("@/components/report/ReportFilter.vue"),
    ReportKpiBar: () => import("@/components/report/ReportKPIBar.vue"),
    ReportTable: () => import("@/components/report/ReportTable.vue"),
    ReportAlert: () => import("@/components/report/ReportAlert.vue")
  },
  computed: {
    options: get("report/optionsTerminals"),
    ...sync("report", ["currentTab"]),
    errorMessage: get("report/errorMessages@export"),
    ...mapState({ series: state => state.report.series }),
    ...mapState({ chartOptions: state => state.report.chartOptions })
  },
  data() {
    return { json2xlsx };
  },
  methods: {
    ...mapActions({
      resetReport: "report/resetTerminalsReport",
      fetchOrganizations: "report/fetchTerminalsOrganizations",
      fetchReport: "report/fetchTerminalsData"
    }),
    initialize() {
      this.currentTab = this.options.tabs[0];
      this.selectTab(this.currentTab);
    },
    selectTab(tab) {
      const link = tab.link.toLowerCase();
      this.fetchOrganizations(link);
      this.resetReport(tab);
      this.showDefaultChart(link);
    },
    showDefaultChart(link) {
      const dateFrom = format(startOfYear(Date.now()), "yyyy-MM-dd");
      const dateTo = format(Date.now(), "yyyy-MM-dd");
      const options = {
        path: link,
        params: {
          organizationId: 0,
          date_from: dateFrom,
          date_to: dateTo
        }
      };
      this.fetchReport(options);
    },
    exportTableToXLSX(currentTab) {
      this.options.data.length > 0
        ? json2xlsx({
            data: this.options.data,
            header: [this.options.headers.map(header => header.text)],
            sheetName: "Отчет",
            fileName:
              "ИСУНГ - " + this.options.title + " - " + currentTab.name + ".xlsx"
          })
        : console.log(this.errorMessage);
    },
  },
  async mounted() {
    this.initialize();
  }
});
</script>
